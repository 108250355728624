// BlogSection.jsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlogPosts } from '../slices/blogSlice'; // adjust the path as needed
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import '../CSS/BlogSection.css';

// Helper function to extract plain text and create a clean excerpt.
const getPlainTextExcerpt = (htmlContent, maxLength = 100) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlContent;
  let text = tempElement.textContent || tempElement.innerText || "";
  text = text.trim().replace(/\s+/g, ' ');
  if (text.length > maxLength) {
    text = text.substring(0, maxLength) + '...';
  }
  return text;
};

const BlogSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { posts, postsLoading, postsError } = useSelector(state => state.blog);

  useEffect(() => {
    // Dispatch the thunk to fetch posts on component mount
    dispatch(fetchBlogPosts());
  }, [dispatch]);

  // Sort posts by created_at (newest first) and get the latest three
  const sortedPosts = [...posts].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );
  const latestPosts = sortedPosts.slice(0, 3);

  return (
    <section className="blog-section">
      <div className="blog-header">
        <div className="blog-header-left">
          <div className='blog-title-section'>
          <h4 className="blog-subtitle">Blog &amp; Article</h4>
          <h2 className="blog-title">Our Latest Blogs</h2>
          </div>
          <p className="blog-intro">
            Fusce non egestas felis, in rhoncus est. Morbi non congue orci. Sed venenatis varius convallis. 
            Aenean vitae hendrerit est, eu molestie orci. Vivamus consectetur molestie lacus, at mattis augue porta et.
          </p>
        </div>
        <div className="blog-header-right">
          <button
            className="view-more-button"
            onClick={() => navigate('/blogs')}
          >
            View More
          </button>
        </div>
      </div>

      {postsLoading && <p>Loading posts...</p>}
      {postsError && <p>Error: {postsError}</p>}

      <div className="blog-cards">
        {latestPosts.map((post, index) => {
          // Extract a clean excerpt without HTML tags
          const excerpt = getPlainTextExcerpt(post.content, 100);
          return (
            <div className="blog-card" key={index}>
              <div className="blog-card-image">
                <img
                  src={post.cover_image || 'https://via.placeholder.com/350x200'}
                  alt={post.title}
                />
              </div>
              <div className="blog-card-content">
                <h3 className="blog-card-title">{post.title}</h3>
                <p className="blog-card-excerpt">{excerpt}</p>
                <button
                  className="blog-card-button"
                  onClick={() => navigate(`/blog/${post.id}`)}
                >
                  Learn More
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default BlogSection;
