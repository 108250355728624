// BlogPage.jsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlogPosts } from '../slices/blogSlice'; // adjust the path as needed
import { useNavigate } from 'react-router-dom';
import { User, CalendarDays } from 'lucide-react';
import Bannersmall from '../Components/Bannersmall';
import DOMPurify from 'dompurify';

const styles = `
  .blog-layout-container {
    max-width: 80%;
    margin: 0 auto;
    padding: 24px;
  }
  .blog-layout-grid {
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 24px;
  }
  /* Card styles */
  .blog-card {
    background: white;
    border-radius: 3px;
    transition: box-shadow 0.3s ease;
    text-align: left;
  }
  .blog-card:hover {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  }
  /* Featured post styles */
  .blog-featured-article {
    margin-bottom: 24px;
    width: 100%;
  }
  .blog-featured-image {
    width: 100%;
    height: 450px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
  }
  .blog-article-content {
    padding: 5px 20px 24px;
  }
  .blog-article-title {
    font-size: 22px;
    color: #333;
    margin-bottom: 16px;
    font-weight: 600;
  }
  .blog-article-meta {
    display: flex;
    gap: 16px;
    color: #666;
    margin-bottom: 16px;
    font-size: 13px;
  }
  .blog-meta-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .blog-article-excerpt {
    color: #666;
    line-height: 1.6;
    margin-bottom: 24px;
    font-size:14px;
  }
  /* Button styles */
  .blog-learn-more-btn {
    background: #a7185d;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s ease;
  }
  .blog-learn-more-btn:hover {
    background: #d81b60;
  }
  /* Sidebar styles */
  .blog-sidebar {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 40%;
  }
  /* Latest posts styles */
  .blog-latest-posts {
    padding: 15px;
    width: 100%;
    background-color: #fbfbfb;
    border: none;
  }
  .blog-latest-posts-heading {
    font-size: 20px;
    color: #333;
    margin-bottom: 5px;
    font-weight: 600;
  }
  .blog-posts-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .blog-post-preview {
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  .blog-post-preview:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  .blog-preview-title {
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
    transition: color 0.3s ease;
  }
  .blog-preview-title:hover {
    color: #e91e63;
    cursor: pointer;
  }
  /* Newsletter styles with overlay */
  .blog-newsletter-card {
    position: relative;
    width: 100%;
    height: 300px; /* Adjust height as needed */
    background: url('/static/banner/banner3.webp') center/cover no-repeat;
    color: white;
    overflow: hidden;
    padding: 24px;
  }
  .blog-newsletter-card::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(142, 59, 110, 0.46);
    z-index: 1;
  }
  .blog-newsletter-card > * {
    position: relative;
    z-index: 2;
  }
  .blog-newsletter-heading {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .blog-newsletter-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .blog-newsletter-input {
    padding: 12px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
  }
  .blog-newsletter-input:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3);
  }
  .blog-newsletter-submit {
    background: #a7185d;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s ease;
  }
  .blog-newsletter-submit:hover {
    background: #ad1457;
  }
  /* Responsive styles */
  @media (max-width: 768px) {

  .blog-layout-container {
    max-width: 95%;
    margin: 0 auto;
    padding: 24px 0;
}
    .blog-layout-grid {
      flex-direction: column-reverse;
    }
      .blog-sidebar {
    display: flex
;
    flex-direction: column-reverse;
    gap: 24px;
    width: 100%;
}
    .blog-featured-image {
      height: 300px;
    }
  }
`;

// Helper function to extract plain text from HTML and create an excerpt.
const getPlainTextExcerpt = (htmlContent, maxLength = 200) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlContent;
  let text = tempElement.textContent || tempElement.innerText || "";
  text = text.trim().replace(/\s+/g, ' ');
  if (text.length > maxLength) {
    text = text.substring(0, maxLength) + '...';
  }
  return text;
};

const BlogPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { posts, postsLoading, postsError } = useSelector(state => state.blog);
  const [email, setEmail] = useState('');

  useEffect(() => {
    // Fetch posts when the component mounts
    dispatch(fetchBlogPosts());
  }, [dispatch]);

  // Sort posts by created_at in descending order
  const sortedPosts = [...posts].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );
  // Get the latest three posts for the sidebar
  const latestPosts = sortedPosts.slice(0, 3);

  const handleSubscribe = (e) => {
    e.preventDefault();
    console.log('Subscribed with email:', email);
    setEmail('');
  };

  const imageUrl = '/static/banner/blog-page.jpg';
  const altText = 'blog banner';

  // (Optional) If you need sanitized HTML for other purposes.
  const createSafeMarkup = (htmlContent) => {
    return { __html: DOMPurify.sanitize(htmlContent) };
  };

  return (
    <>
      <style>{styles}</style>
<div className='service-banner-text'>
         <Bannersmall imageUrl={imageUrl} altText={altText} />
         <span>Blogs</span>
         </div>      <div className="blog-layout-container">
        <div className="blog-layout-grid">
          {/* Sidebar */}
          <aside className="blog-sidebar">
            <div className="blog-card blog-latest-posts">
              <h2 className="blog-latest-posts-heading">Our Latest Posts</h2>
              {postsLoading && <p>Loading...</p>}
              {postsError && <p>Error: {postsError}</p>}
              <div className="blog-posts-list">
                {latestPosts.map((post, index) => (
                  <div key={index} className="blog-post-preview">
                    <h3
                      className="blog-preview-title"
                      onClick={() => navigate(`/blog/${post.id}`)}
                    >
                      {post.title}
                    </h3>
                    <div className="blog-article-meta">
                      <div className="blog-meta-item">
                        <User className="blog-meta-icon" size={16} />
                        <span>
                          {post.author && post.author.username ? post.author.username : 'Unknown'}
                        </span>
                      </div>
                      <div className="blog-meta-item">
                        <CalendarDays className="blog-meta-icon" size={16} />
                        <span>
                          {new Date(post.created_at).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="blog-card blog-newsletter-card">
              <h2 className="blog-newsletter-heading">Subscribe For Our Newsletter</h2>
              <form onSubmit={handleSubscribe} className="blog-newsletter-form">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="blog-newsletter-input"
                  required
                />
                <button type="submit" className="blog-newsletter-submit">
                  Subscribe Now
                </button>
              </form>
            </div>
          </aside>

          {/* Main Content */}
          <div>
            {postsLoading && <p>Loading posts...</p>}
            {postsError && <p>Error: {postsError}</p>}
            {sortedPosts.map((post, index) => {
              // Use the helper function to extract a clean excerpt from the HTML content.
              const excerpt = getPlainTextExcerpt(post.content, 200);
              return (
                <article
                  key={index}
                  className="blog-card blog-featured-article"
                  style={{ marginBottom: '24px' }}
                >
                  <img
                    src={post.cover_image || '/api/placeholder/800/600'}
                    alt={post.title}
                    className="blog-featured-image"
                  />
                  <div className="blog-article-content">
                    <h1 className="blog-article-title">{post.title}</h1>
                    <div className="blog-article-meta">
                      <div className="blog-meta-item">
                        <User className="blog-meta-icon" size={16} />
                        <span>
                          {post.author && post.author.username ? post.author.username : 'Unknown'}
                        </span>
                      </div>
                      <div className="blog-meta-item">
                        <CalendarDays className="blog-meta-icon" size={16} />
                        <span>
                          {new Date(post.created_at).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                    {/* Render the clean excerpt */}
                    <p className="blog-article-excerpt">{excerpt}</p>
                    <button
                      className="blog-learn-more-btn"
                      onClick={() => navigate(`/blog/${post.id}`)}
                    >
                      Learn More
                    </button>
                  </div>
                </article>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPage;
