// src/App.js

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { HashRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Provider, useSelector, useDispatch, shallowEqual } from 'react-redux';
import store from './store';

// Layout & Components
import Header from './Components/Header';
import Footer from './Components/Footer';
import ScrollToTop from './Components/ScrollToTop';
import WhatsAppButton from './Pages/WhatsAppButton';
import LanguageSelector from './Components/LanguageSelector';
import Notifications from './Components/Notifications';
import NotificationPermission from './NotificationPermission';
import GlobalCallNotification from './GlobalCallNotification';

// Pages
import Index from './Pages/Index';
import Services from './Pages/Services';
import ServicesDetails from './Pages/ServicesDetails';
import AboutUS from './Pages/AboutUs';
import SignUp from './Pages/BookAnAppointment';
import ChatApp from './Pages/ChatApp';
import HealthcareServiceForm from './Pages/ServiceForm';
import MedicalTourism from './Pages/MedicalTourism';
import Dashboard from './Pages/UserDashboard';
import FounderMess from './Pages/FounderMess';
import DoctorDashboard from './Pages/DoctorDashboard';
import DoctorChatApp from './Pages/DoctorChat';
import DoctorRegistrationForm from './Pages/DoctorRegistrationForm';
import LoginPage from './Pages/DoctorLogin';
import PatientDetails from './Pages/PatientDetails';
import TermsAndConditions from './Pages/Terms';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import RefundPolicy from './Pages/Refund';
import AppointmentDashboard from './Pages/AdminDashboard';
import DoctorProfileUpdate from './Pages/DoctorDetails';
import DoctorChatPage from './Pages/DoctorChatPage';
import AdminLogin from './Pages/AdminLogin';
import AdminPatientDashboard from './Pages/AdminPatientDashboard';
import AdminDoctorDashboard from './Pages/AdminDoctorDashboard';
import AppointmentAndQuotePage from './Components/AppointmentAndQuotePage';
import BlogCreatePostPage from './Pages/BlogCreatePostPage';
import BlogLoginPage from './Pages/BlogLoginPage';
import UserFileManager from './Pages/UserFileManager';
import VideoCallManager from "./VideoCallManager"; // <-- our stable manager
import BlogPage from './Pages/BlogPage';
import SingleBlogPage from './Pages/SingleBlogPage';
import {
  setCurrentRoom,
  clearCurrentRoom,
  endCall,
} from './slices/notificationSlice';

import webSocketManager from './services/WebSocketManager';
import { generateRoomName } from './utils';

// The actual VideoCall component for WebRTC
import VideoCall from './Components/VideoCall';

import './App.css';

/**
 * The main App component
 */
function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Router>
          <ScrollToTop />
         
          {/* Initialize the WebSocket once */}
          <WebSocketInitializer />

          {/* Permissions, global notifications, etc. */}
          <NotificationPermission />
          <VideoCallManager />

          <GlobalCallNotification />

          {/* The main layout + routes */}
          <Layout>
            <Notifications />
            <Routes>
              <Route exact path="/" element={<Index />} />
              <Route exact path="/services" element={<Services />} />
              <Route exact path="/services/:serviceId" element={<ServicesDetails />} />
              <Route exact path="/aboutus" element={<AboutUS />} />
              <Route exact path="/bookappointment" element={<SignUp />} />
              <Route exact path="/medicaltourism" element={<MedicalTourism />} />
              <Route exact path="/admin/serviceform" element={<HealthcareServiceForm />} />
              <Route exact path="/userdashboard" element={<Dashboard />} />
              <Route exact path="/appointmentpage" element={<AppointmentAndQuotePage />} />
              <Route exact path="/foundermessage" element={<FounderMess />} />
              <Route exact path="/doctors/doctordashboard" element={<DoctorDashboard />} />
              <Route exact path="/doctors/chat/:patientId" element={<DoctorChatApp />} />
              <Route exact path="/doctors/registration" element={<DoctorRegistrationForm />} />
              <Route exact path="/doctors/login" element={<LoginPage />} />
              <Route exact path="/doctors/personal-details" element={<DoctorProfileUpdate />} />
              <Route exact path="/terms" element={<TermsAndConditions />} />
              <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
              <Route exact path="/refund_policy" element={<RefundPolicy />} />
              <Route exact path="/personal-details" element={<PatientDetails />} />
              <Route exact path="/chat" element={<ChatApp />} />
              <Route exact path="/admin/admin-dashboard" element={<AppointmentDashboard />} />
              <Route exact path="/doctors/chatpage" element={<DoctorChatPage />} />
              <Route exact path="/admin/login" element={<AdminLogin />} />
              <Route exact path="/admin/patients" element={<AdminPatientDashboard />} />
              <Route exact path="/admin/doctors" element={<AdminDoctorDashboard />} />
              <Route exact path="/blog/login" element={<BlogLoginPage />} />
              <Route exact path="/blog/create" element={<BlogCreatePostPage />} />
              <Route exact path="/userfilemanager" element={<UserFileManager />} />
              <Route exact path="/blogs" element={<BlogPage />} />
              <Route path="/blog/:id" element={<SingleBlogPage />} />

              
            </Routes>
          </Layout>

          {/* VideoCallManager: Only one place that mounts <VideoCall> */}
         
        </Router>
      </div>
    </Provider>
  );
}

/**
 * WebSocketInitializer
 * - Connects to your WebSocket with the correct token
 * - Subscribes/unsubscribes to roomName based on the selectedUser
 */
const WebSocketInitializer = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const doctorData = useSelector((state) => state.doctor.doctorData);
  const userData = useSelector((state) => state.user.userData);

  const [selectedUser, setSelectedUser] = useState(() => {
    const storedUser = localStorage.getItem('selectedUser');
    if (storedUser) {
      try {
        return JSON.parse(storedUser);
      } catch (error) {
        console.error('Error parsing storedUser:', error);
        return null;
      }
    } else if (location.state && location.state.selectedUser) {
      return location.state.selectedUser;
    }
    return null;
  });

  // If a new selectedUser is passed via route, store it
  useEffect(() => {
    if (location.state && location.state.selectedUser) {
      setSelectedUser(location.state.selectedUser);
      localStorage.setItem(
        'selectedUser',
        JSON.stringify(location.state.selectedUser)
      );
    }
  }, [location.state]);

  // Connect to the WebSocket on mount or whenever userData/doctorData changes
  useEffect(() => {
    let token = null;

    if (doctorData?.access_token) {
      token = doctorData.access_token;
    } else if (userData?.access_token) {
      token = userData.access_token;
    } else {
      // Fallback from localStorage
      const storedDoctorData = localStorage.getItem('doctorData');
      const storedUserData = localStorage.getItem('userData');
      if (storedDoctorData) {
        try {
          const parsed = JSON.parse(storedDoctorData);
          if (parsed.access_token) token = parsed.access_token;
        } catch (err) {
          console.error('Error parsing storedDoctorData:', err);
        }
      }
      if (!token && storedUserData) {
        try {
          const parsed = JSON.parse(storedUserData);
          if (parsed.access_token) token = parsed.access_token;
        } catch (err) {
          console.error('Error parsing storedUserData:', err);
        }
      }
    }

    if (token) {
      webSocketManager.connect(token);
    } else {
      console.warn('No token found. Not connecting WebSocket.');
    }
  }, [doctorData, userData]);

  // Subscribe to the “roomName” derived from current + selectedUser
  useEffect(() => {
    if (!selectedUser) return;

    // figure out local user ID
    const currentUserData = doctorData || userData;
    if (!currentUserData) return;

    const currentUserId =
      currentUserData?.doctor?.user?.id ||
      currentUserData?.patient?.user?.id ||
      currentUserData?.user?.id ||
      currentUserData.id;

    if (!currentUserId) return;

    const selectedUserId = selectedUser.user.id;
    const roomName = generateRoomName(currentUserId, selectedUserId);

    // Subscribe to that room
    webSocketManager.subscribe(roomName);
    dispatch(setCurrentRoom(roomName));

    return () => {
      webSocketManager.unsubscribe(roomName);
      dispatch(clearCurrentRoom());
    };
  }, [selectedUser, doctorData, userData, dispatch]);

  return null;
};



/**
 * Layout
 * - Hide or show header/footer on certain routes
 */
function Layout({ children }) {
  const location = useLocation();

  const noHeaderFooterRoutes = [
    '/userdashboard',
    '/doctors/doctordashboard',
    '/chat',
    '/doctors/registration',
    '/doctors/login',
    '/personal-details',
    '/admin/admin-dashboard',
    '/doctors/personal-details',
    '/doctors/chatpage',
    '/admin/login',
    '/admin/patients',
    '/admin/doctors',
    '/appointmentpage',
    '/blog/login',
    '/blog/create',
    '/userfilemanager'
  ];

  const isHeaderFooterHidden =
    noHeaderFooterRoutes.includes(location.pathname) ||
    location.pathname.startsWith('/doctors/chat/');

  const isChatPage =
    location.pathname === '/chat' ||
    location.pathname === '/doctors/chatpage' ||
    location.pathname.startsWith('/doctors/chat/');

  return (
    <>
      {!isHeaderFooterHidden && <Header />}
      <main>{children}</main>
      {!isHeaderFooterHidden && <Footer />}
      {!isChatPage && <WhatsAppButton />}
      {!isHeaderFooterHidden && <LanguageSelector />}
    </>
  );
}

export default App;
