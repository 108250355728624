// src/services/WebSocketManager.js

import store from "../store"; 
import axios from "axios";
import {
  receiveMessage,
  receiveCallNotification,
  receiveSignalingMessage,
  receiveError,
  prependMessages,
  updateUserStatus,
  updateMessageStatus,
  endCall,
  receiveCallAccepted
} from "../slices/notificationSlice";

/**
 * If you have a helper that builds "roomName" from two user IDs, you can still use it.
 * But now, with multi-user logic, you typically just have 1 room for an appointment,
 * like: "patientId_mainDoctorId". Additional doctors also join that same room.
 */
import { generateRoomName } from "../utils"; 

class WebSocketManager {
  constructor() {
    if (WebSocketManager.instance) {
      return WebSocketManager.instance;
    }
    this.socket = null;
    this.isConnected = false;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.reconnectDelay = 3000; // 3 seconds
    this.messageQueue = [];
    this.token = null; 
    this.beforeMap = {}; 
    this.hasMoreMap = {}; 
    this.messageHandlers = []; 
    this.desiredRooms = new Set(); 

    WebSocketManager.instance = this;
  }

  /**
   * Example method to upload files via HTTP and return their URLs.
   * Adapt to your backend's upload endpoints.
   */
  async uploadFiles(files) {
    const uploadUrl = "/api/upload/";
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));

    try {
      const response = await axios.post(uploadUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.token}`,
        },
      });
      return response.data.fileUrls; // e.g. ["https://example.com/file1.png", ...]
    } catch (error) {
      console.error("File upload failed:", error);
      throw error;
    }
  }

  /**
   * Example: fetch rooms (appointments) for the user. 
   * This can still remain similar to your original approach.
   */
  async fetchUserChatRooms() {
    try {
      const state = store.getState();
      let userType = null;
      let currentUserId = null;

      // 1) Check in Redux (patient or doctor)
      if (state.user?.userData?.patient?.user?.id) {
        userType = "patient";
        currentUserId = state.user.userData.patient.user.id;
      } else if (state.doctor?.doctorData?.doctor?.user?.id) {
        userType = "doctor";
        currentUserId = state.doctor.doctorData.doctor.user.id;
      } else {
        // 2) Fallback: localStorage
        const storedUserData = JSON.parse(localStorage.getItem("userData"));
        const storedDoctorData = JSON.parse(localStorage.getItem("doctorData"));
        if (storedUserData?.patient?.user?.id) {
          userType = "patient";
          currentUserId = storedUserData.patient.user.id;
        } else if (storedDoctorData?.doctor?.user?.id) {
          userType = "doctor";
          currentUserId = storedDoctorData.doctor.user.id;
        }
      }

      if (!currentUserId) {
        throw new Error("Unable to determine current user ID.");
      }
      if (!this.token) {
        throw new Error("Authentication token is not available.");
      }

      let appointments = [];
      if (userType === "patient") {
        const endpoint = "/api/get_patient_appointments/";
        const response = await axios.get(endpoint, {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        appointments = Array.isArray(response.data) ? response.data : [];
      } else if (userType === "doctor") {
        const endpoint = "/api/doctor/appointments/";
        const response = await axios.get(endpoint, {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        appointments = Array.isArray(response.data) ? response.data : [];
      }

      if (!appointments.length) {
        return [];
      }

      // Build unique room names from each appointment
      const roomNameSet = new Set();
      appointments.forEach((appt) => {
        const doctorId = appt?.doctor?.user?.id;
        const patientId = appt?.patient?.user?.id;
        if (doctorId && patientId) {
          // Typically "roomName = '<patientId>_<doctorId>'"
          const roomName = generateRoomName(doctorId, patientId);
          roomNameSet.add(roomName);
        }
      });
      return Array.from(roomNameSet).map((roomName) => ({ roomName }));
    } catch (error) {
      console.error("fetchUserChatRooms => Error:", error);
      throw error;
    }
  }

  async connect(token) {
    if (
      this.socket &&
      (this.socket.readyState === WebSocket.OPEN ||
        this.socket.readyState === WebSocket.CONNECTING)
    ) {
      console.warn("WebSocket is already open/connecting. Skipping new connect().");
      return;
    }
    if (!token) {
      console.error("connect => Token is required.");
      return;
    }
    this.token = token;

    const wsScheme = window.location.protocol === "https:" ? "wss" : "ws";
    const socketUrl = `${wsScheme}://${window.location.host}/ws/chat/?token=${encodeURIComponent(
      token
    )}`;

    this.socket = new WebSocket(socketUrl);

    this.socket.onopen = async () => {
      console.log("WebSocket onopen fired.");
      this.reconnectAttempts = 0;
      this.isConnected = true;

      try {
        // Subscribe to all rooms relevant to this user
        const rooms = await this.fetchUserChatRooms();
        rooms.forEach((room) => this.subscribe(room.roomName));

        // Also subscribe to presence if your server uses it
        this.subscribe("presence");

        // Send queued messages
        while (this.messageQueue.length > 0) {
          const msg = this.messageQueue.shift();
          this.send(msg);
        }
      } catch (err) {
        console.error("Error during WebSocket onopen initialization:", err);
      }
    };

    this.socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        this.handleIncomingMessage(data);
      } catch (err) {
        console.error("Error parsing WebSocket message:", err);
      }
    };

    this.socket.onclose = (event) => {
      console.log("WebSocket onclose fired:", event);
      this.socket = null;
      this.isConnected = false;
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        this.reconnectAttempts++;
        const delay = this.reconnectDelay * this.reconnectAttempts;
        console.log(`Attempt reconnect #${this.reconnectAttempts} in ${delay / 1000}s`);
        setTimeout(() => this.connect(this.token), delay);
      } else {
        console.error("Max reconnect attempts reached. Please refresh the page.");
        store.dispatch(
          receiveError({
            message: "Unable to connect. Please refresh.",
            roomName: null,
          })
        );
      }
    };

    this.socket.onerror = (error) => {
      console.error("WebSocket onerror:", error);
      this.safeClose();
      store.dispatch(
        receiveError({
          message: "WebSocket encountered an error. Connection closed.",
          roomName: null,
        })
      );
    };
  }

  handleIncomingMessage(data) {
    // Optional: If data.from == currentUserId, skip to avoid self-echo
    switch (data.type) {
      case "chat_message":
      case "chat_message_event":
        this.handleChatMessage(data);
        break;
      case "previous_messages":
        this.handlePreviousMessages(data);
        break;
      case "call":
      case "call_message_event":
        this.handleCallNotification(data);
        break;
      case "offer":
      case "offer_event":
      case "answer":
      case "answer_event":
      case "ice-candidate":
      case "ice_candidate_event":
        this.handleSignalingMessage(data);
        break;
      case "call-accepted":
      case "call_accepted_message_event":
        this.handleCallAccepted(data);
        break;
      case "end-call":
      case "end_call_message_event":
        this.handleEndCall(data);
        break;
      case "user_status":
        this.handleUserStatus(data);
        break;
      case "message_status":
        this.handleMessageStatus(data);
        break;
      case "error":
        this.handleError(data);
        break;
      default:
        console.warn("Unhandled message type:", data.type);
    }

    // Also notify any external handlers
    this.messageHandlers.forEach((handler) => handler(data));
  }

  subscribe(roomName) {
    if (this.desiredRooms.has(roomName)) {
      console.log("Already subscribed to:", roomName);
      return;
    }
    this.desiredRooms.add(roomName);
    this.send({ type: "subscribe", room: roomName });

    // Optionally fetch initial messages
    this.fetchPreviousMessages(roomName, 10);
  }

  unsubscribe(roomName) {
    if (!this.desiredRooms.has(roomName)) {
      console.warn("Not subscribed to room:", roomName);
      return;
    }
    this.send({ type: "unsubscribe", room: roomName });
    this.desiredRooms.delete(roomName);
    delete this.beforeMap[roomName];
    delete this.hasMoreMap[roomName];
  }

  onMessage(handler) {
    if (typeof handler === "function") {
      this.messageHandlers.push(handler);
    } else {
      console.error("onMessage => handler must be a function.");
    }
  }

  offMessage(handler) {
    this.messageHandlers = this.messageHandlers.filter((h) => h !== handler);
  }

  /**
   * New group-based approach:
   * fetchPreviousMessages only needs (roomName, limit, and optional 'before').
   */
  fetchPreviousMessages(roomName, limit = 10) {
    if (this.hasMoreMap[roomName] === false) {
      console.log("No more messages to fetch for room:", roomName);
      return;
    }
    const before = this.beforeMap[roomName] || null;
    const payload = {
      type: "fetch_previous_messages",
      roomName,
      limit,
    };
    if (before) {
      payload.before = before;
    }
    this.send(payload);
  }

  // ---------------------------
  // Chat Message
  // ---------------------------
  async sendMessage(roomName, message, files = []) {
    /**
     * Instead of "toUserId", we now only pass a "roomName".
     * Everyone in that room sees the message.
     */
    if (!roomName || !message) {
      console.error("sendMessage => Missing roomName or message.");
      return;
    }

    // Upload files first
    let fileUrls = [];
    if (files.length) {
      try {
        fileUrls = await this.uploadFiles(files);
      } catch (err) {
        store.dispatch(
          receiveError({
            message: "Failed to upload files. Message not sent.",
            roomName,
          })
        );
        return;
      }
    }

    const payload = {
      type: "chat_message",
      roomName,
      message,
      files: fileUrls,
    };
    this.send(payload);
  }

  handleChatMessage(data) {
    const { roomName, sender, senderFullName, message, files, timestamp, id } = data;
    if (!roomName || !sender || !message || !id) {
      console.error("Invalid chat_message payload:", data);
      return;
    }

    const currentUserId = this.getCurrentUserId();
    const formattedFiles = Array.isArray(files)
      ? files.map((file) =>
          typeof file === "string" ? { name: file, url: file } : file
        )
      : [];

    store.dispatch(
      receiveMessage({
        roomName,
        id: String(id),
        sender,
        senderFullName,
        content: message,
        files: formattedFiles,
        timestamp: timestamp || new Date().toISOString(),
        isCurrentUser: sender === currentUserId,
        isNew: true,
      })
    );
  }

  handlePreviousMessages(data) {
    const { roomName, messages, hasMore } = data;
    if (!roomName || !Array.isArray(messages)) {
      console.warn("previous_messages => invalid payload:", data);
      return;
    }
    this.hasMoreMap[roomName] = hasMore;
    if (!messages.length) {
      store.dispatch(
        prependMessages({
          roomName,
          messages: [],
        })
      );
      return;
    }

    // Reverse so oldest first
    const ascending = messages.reverse();
    const currentUserId = this.getCurrentUserId();
    const formatted = ascending
      .map((m) => {
        if (!m.id || !m.sender || !m.message) {
          console.warn("Invalid message format:", m);
          return null;
        }
        const filesArr = Array.isArray(m.files)
          ? m.files.map((f) =>
              typeof f === "string" ? { name: f, url: f } : f
            )
          : [];
        return {
          id: String(m.id),
          roomName,
          sender: m.sender,
          senderFullName: m.senderFullName,
          content: m.message,
          files: filesArr,
          timestamp: m.timestamp || new Date().toISOString(),
          status: m.status,
          isCurrentUser: m.sender === currentUserId,
          isNew: false,
        };
      })
      .filter(Boolean);

    store.dispatch(
      prependMessages({
        roomName,
        messages: formatted,
      })
    );

    // Save the 'before' as earliest
    if (formatted[0]) {
      this.beforeMap[roomName] = formatted[0].timestamp;
    }
  }

  // ---------------------------
  // Group Call
  // ---------------------------
  initiateCall(roomName, callType = "audio") {
    if (!roomName || !callType) {
      console.error("initiateCall => Missing roomName or callType.");
      return;
    }
    const payload = {
      type: "call",
      roomName,
      call_type: callType,
    };
    this.send(payload);
  }

  handleCallNotification(data) {
    const { from, fromFullName, callType, roomName, patientId, doctorId } = data;
    if (!from || !fromFullName || !callType || !roomName) {
      console.error("Invalid call notification payload:", data);
      return;
    }
    const callNotificationPayload = {
      fromId: from,
      fromFullName,
      callType,
      roomName,
    };
    if (patientId) callNotificationPayload.patientId = patientId;
    if (doctorId) callNotificationPayload.doctorId = doctorId;

    store.dispatch(receiveCallNotification(callNotificationPayload));
  }

  acceptCall(roomName) {
    if (!roomName) {
      console.error("acceptCall => Missing roomName.");
      return;
    }
    const payload = {
      type: "call-accepted",
      roomName,
    };
    this.send(payload);
  }

  handleCallAccepted(data) {
    const { from, fromFullName, roomName } = data;
    if (!from || !roomName) {
      console.error("handleCallAccepted => missing 'from' or 'roomName':", data);
      return;
    }
    store.dispatch(
      receiveCallAccepted({
        fromUserId: from,
        fromFullName,
        roomName,
      })
    );
  }

  endCall(roomName) {
    if (!roomName) {
      console.error("endCall => Missing roomName.");
      return;
    }
    const payload = {
      type: "end-call",
      roomName,
    };
    this.send(payload);
  }

  handleEndCall(data) {
    const { from, fromFullName, roomName } = data;
    if (!from || !fromFullName || !roomName) {
      console.error("end-call => missing fields:", data);
      return;
    }
    store.dispatch(
      endCall({
        roomName,
        fromUserId: from,
        fromFullName,
      })
    );
  }

  // ---------------------------
  // WebRTC Signaling
  // ---------------------------
  sendSignalingMessage(roomName, signalType, payload = {}) {
    if (!roomName || !signalType) {
      console.error("sendSignalingMessage => missing roomName or signalType.");
      return;
    }
    const message = {
      type: signalType, // 'offer', 'answer', 'ice-candidate'
      roomName,
      ...payload,
    };
    this.send(message);
  }

  handleSignalingMessage(data) {
    const { type, from, fromFullName, roomName, ...rest } = data;
    if (!from || !roomName) {
      console.error("handleSignalingMessage => missing 'from' or 'roomName':", data);
      return;
    }
    switch (type) {
      case "offer":
      case "offer_event":
        store.dispatch(
          receiveSignalingMessage({
            signalType: "offer",
            fromId: from,
            roomName,
            offer: rest.offer,
          })
        );
        break;
      case "answer":
      case "answer_event":
        store.dispatch(
          receiveSignalingMessage({
            signalType: "answer",
            fromId: from,
            roomName,
            answer: rest.answer,
          })
        );
        break;
      case "ice-candidate":
      case "ice_candidate_event":
        store.dispatch(
          receiveSignalingMessage({
            signalType: "ice-candidate",
            fromId: from,
            roomName,
            candidate: rest.candidate,
          })
        );
        break;
      default:
        console.warn("Unhandled signaling type:", type);
    }
  }

  // ---------------------------
  // Presence / User Status
  // ---------------------------
  subscribeToUserStatus() {
    // If you want a single 'presence' room for presence tracking:
    if (!this.desiredRooms.has("presence")) {
      this.subscribe("presence");
    }
  }

  sendPresenceStatus(status) {
    this.send({
      type: "user_status",
      status, // 'available', 'busy', or 'offline'
    });
  }

  handleUserStatus(data) {
    const { userId, userFullName, status } = data;
    if (!userId || !userFullName || !status) {
      console.error("Invalid user_status payload:", data);
      return;
    }
    store.dispatch(
      updateUserStatus({
        userId,
        userFullName,
        status,
      })
    );
  }

  // ---------------------------
  // Misc
  // ---------------------------
  handleMessageStatus(data) {
    // If your server/consumer sends "message_status" events
    const { message_id, status } = data;
    if (!message_id || !status) return;
    store.dispatch(updateMessageStatus({ message_id, status }));
  }

  handleError(data) {
    const { message, roomName } = data;
    if (!message) return;
    store.dispatch(
      receiveError({
        message,
        roomName: roomName || null,
      })
    );
  }

  /**
   * Send data or queue if socket is not open.
   */
  send(data) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      try {
        this.socket.send(JSON.stringify(data));
      } catch (err) {
        console.error("Error sending message:", err);
        this.messageQueue.push(data);
      }
    } else {
      this.messageQueue.push(data);
    }
  }

  safeClose() {
    if (this.socket && this.socket.readyState !== WebSocket.CLOSED) {
      this.socket.close();
      this.socket = null;
      this.isConnected = false;
    }
  }

  close() {
    this.safeClose();
    this.desiredRooms.clear();
    this.messageQueue = [];
    this.token = null;
    this.reconnectAttempts = 0;
    this.messageHandlers = [];
    this.beforeMap = {};
    this.hasMoreMap = {};
  }

  getState() {
    return this.socket ? this.socket.readyState : null;
  }

  getCurrentUserId() {
    // Same logic as before:
    const state = store.getState();
    if (state.user?.userData?.patient?.user?.id) {
      return state.user.userData.patient.user.id;
    }
    if (state.doctor?.doctorData?.doctor?.user?.id) {
      return state.doctor.doctorData.doctor.user.id;
    }
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    const storedDoctorData = JSON.parse(localStorage.getItem("doctorData"));
    if (storedUserData?.patient?.user?.id) {
      return storedUserData.patient.user.id;
    }
    if (storedDoctorData?.doctor?.user?.id) {
      return storedDoctorData.doctor.user.id;
    }
    return null;
  }

  /**
   * (Optional) Generate a unique ID if needed for local message tracking
   */
  generateUniqueId() {
    return `${Date.now()}-${Math.random().toString(36).substring(2, 11)}`;
  }
}

const webSocketManager = new WebSocketManager();
export default webSocketManager;
