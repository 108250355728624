// SingleBlogPage.jsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchBlogPosts } from '../slices/blogSlice'; // Adjust the path as needed
import { User, CalendarDays } from 'lucide-react';
import Bannersmall from '../Components/Bannersmall';
import DOMPurify from 'dompurify';

const styles = `
  .blog-layout-container {
    max-width: 80%;
    margin: 0 auto;
    padding: 24px;
  }
  .blog-layout-grid {
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 24px;
  }
  /* Card styles */
  .blog-card {
    background: white;
    border-radius: 3px;
    transition: box-shadow 0.3s ease;
    text-align: left;
    border: none;
  }
  .blog-card:hover {
  }
  /* Featured post styles */
  .blog-featured-article {
    margin-bottom: 24px;
    width: 100%;
  }
  .blog-featured-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
  }
  .blog-article-content {
    padding: 24px;
  }
  .blog-article-title {
    font-size: 38px;
    color: #333;
    margin-bottom: 16px;
    font-weight: 600;
    font-family: "Roboto", serif;
  }
  .blog-article-meta {
    display: flex;
    gap: 16px;
    color: #666;
    margin-bottom: 16px;
    font-size: 14px;
  }
  .blog-meta-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .blog-article-excerpt,
  .blog-article-content-full {
    margin-bottom: 24px;
  }
  /* Adjust spacing for elements inside content */
  .blog-article-content-full h2,
  .blog-article-content-full h3,
  .blog-article-content-full p {
    margin: 15px 0;
  }
  .blog-article-content-full p,
  .blog-article-content-full li {
    font-size: 20px;
    line-height: 32px;
  }
  /* Remove extra spacing from <br> tags inside paragraphs */
  .blog-article-content-full p br {
    display: inline;
    line-height: inherit;
    margin: 0;
    padding: 0;
  }
  .blog-article-content-full a {
    text-decoration: none;
    color: #a7185d;
  }
  /* Apply a simple font (Roboto) to heading tags and strong elements */
  .blog-article-content-full h1,
  .blog-article-content-full h2,
  .blog-article-content-full h3,
  .blog-article-content-full h4,
  .blog-article-content-full h5,
  .blog-article-content-full h6,
  .blog-article-content-full strong {
    font-family: "Roboto", serif;
    color:#333;
  }
  /* New heading styles with font sizes and font-weight */
  .blog-article-content-full h1 {
    font-size: 32px;
    font-weight: 600;
    margin: 15px 0;
  }
  .blog-article-content-full h2 {
    font-size: 30px;
    font-weight: 600;
    margin: 15px 0;
  }
  .blog-article-content-full h3 {
    font-size: 28px;
    font-weight: 600;
    margin: 15px 0;
  }
  .blog-article-content-full h4 {
    font-size: 26px;
    font-weight: 600;
    margin: 15px 0;
  }
  .blog-article-content-full h5 {
    font-size: 24px;
    font-weight: 600;
    margin: 15px 0;
  }
  .blog-article-content-full h6 {
    font-size: 22px;
    font-weight: 600;
    margin: 15px 0;
  }
  .blog-article-content-full strong {
    font-weight: 600;
  }
  .blog-article-content-full a strong {
    font-weight: 400;
  }
  /* New rule: For <p> tags that contain only <br> tags, limit the height to max 5px */
  .blog-article-content-full p:has(> br):not(:has(> :not(br))) {
    max-height: 5px;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  /* Button styles */
  .blog-back-btn,
  .blog-learn-more-btn {
    background: #a7185d;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s ease;
  }
  .blog-back-btn:hover,
  .blog-learn-more-btn:hover {
    background: #d81b60;
  }
  /* Sidebar styles */
  .blog-sidebar {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 25%;
  }
  /* Latest posts styles */
  .blog-latest-posts {
    padding: 15px;
    width: 100%;
    background-color: #fbfbfb;
    border: none;
  }
  .blog-latest-posts-heading {
    font-size: 20px;
    color: #333;
    margin-bottom: 5px;
    font-weight: 600;
  }
  .blog-posts-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .blog-post-preview {
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  .blog-post-preview:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  .blog-preview-title {
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
    transition: color 0.3s ease;
  }
  .blog-preview-title:hover {
    color: #e91e63;
    cursor: pointer;
  }
  /* Newsletter styles with overlay */
  .blog-newsletter-card {
    position: relative;
    width: 100%;
    height: 300px; /* Adjust height as needed */
    background: url('/static/banner/banner3.webp') center/cover no-repeat;
    color: white;
    overflow: hidden;
    padding: 24px;
  }
  .blog-newsletter-card::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(142, 59, 110, 0.46);
    z-index: 1;
  }
  .blog-newsletter-card > * {
    position: relative;
    z-index: 2;
  }
  .blog-newsletter-heading {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .blog-newsletter-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .blog-newsletter-input {
    padding: 12px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
  }
  .blog-newsletter-input:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3);
  }
  .blog-newsletter-submit {
    background: #a7185d;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s ease;
  }
  .blog-newsletter-submit:hover {
    background: #ad1457;
  }
    .single-blog-content{
    width:75%
    }
  /* Responsive styles */
  @media (max-width: 768px) {
   .blog-layout-container {
    max-width: 95%;
    margin: 0 auto;
    padding: 24px 0;
}
    .blog-layout-grid {
      flex-direction: column-reverse;
    }
      .blog-sidebar {
    display: flex
;
    flex-direction: column-reverse;
    gap: 24px;
    width: 100%;
}
    .blog-article-content {
    padding: 24px 0;
}
   
    .blog-featured-image {
      height: 300px;
    }
 .single-blog-content{
    width:100%
    }
  }
`;

const SingleBlogPage = () => {
    const { id } = useParams(); // Retrieve the blog post id from the URL
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { posts, postsLoading, postsError } = useSelector(state => state.blog);
    const [email, setEmail] = useState('');

    useEffect(() => {
        // Fetch posts if not already loaded
        if (!posts.length) {
            dispatch(fetchBlogPosts());
        }
    }, [dispatch, posts.length]);

    // Find the blog post by id (adjust field name if necessary)
    const blogPost = posts.find(
        post => String(post.id) === id || String(post._id) === id
    );

    // Sort posts by created_at in descending order for the sidebar
    const sortedPosts = [...posts].sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    // Get the latest three posts for the sidebar
    const latestPosts = sortedPosts.slice(0, 3);

    const handleSubscribe = (e) => {
        e.preventDefault();
        console.log('Subscribed with email:', email);
        setEmail('');
    };

    // Helper to sanitize HTML content
    const createSafeMarkup = (htmlContent) => {
        return { __html: DOMPurify.sanitize(htmlContent) };
    };

    const imageUrl = '/static/banner/blog-page.jpg';
    const altText = 'blog banner';

    return (
        <>
            <style>{styles}</style>
            <div className='service-banner-text'>
                <Bannersmall imageUrl={imageUrl} altText={altText} />
                <span>Blogs</span>
            </div>
            <div className="blog-layout-container">
                <div className="blog-layout-grid">
                    {/* Sidebar */}
                    <aside className="blog-sidebar">
                        <div className="blog-card blog-latest-posts">
                            <h2 className="blog-latest-posts-heading">Our Latest Posts</h2>
                            {postsLoading && <p>Loading...</p>}
                            {postsError && <p>Error: {postsError}</p>}
                            <div className="blog-posts-list">
                                {latestPosts.map((post, index) => (
                                    <div key={index} className="blog-post-preview">
                                        <h3
                                            className="blog-preview-title"
                                            onClick={() => navigate(`/blog/${post.id || post._id}`)}
                                        >
                                            {post.title}
                                        </h3>
                                        <div className="blog-article-meta">
                                            <div className="blog-meta-item">
                                                <User className="blog-meta-icon" size={14} />
                                                <span>
                                                    {post.author && post.author.username
                                                        ? post.author.username
                                                        : 'Unknown'}
                                                </span>
                                            </div>
                                            <div className="blog-meta-item">
                                                <CalendarDays className="blog-meta-icon" size={14} />
                                                <span>{new Date(post.created_at).toLocaleDateString()}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="blog-card blog-newsletter-card">
                            <h2 className="blog-newsletter-heading">Subscribe For Our Newsletter</h2>
                            <form onSubmit={handleSubscribe} className="blog-newsletter-form">
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="blog-newsletter-input"
                                    required
                                />
                                <button type="submit" className="blog-newsletter-submit">
                                    Subscribe Now
                                </button>
                            </form>
                        </div>
                    </aside>

                    {/* Main Content */}
                    <div className='single-blog-content'>
                        {postsLoading && <p>Loading post...</p>}
                        {postsError && <p>Error: {postsError}</p>}
                        {blogPost ? (
                            <article className="blog-card blog-featured-article">
                                <img
                                    src={blogPost.cover_image || '/api/placeholder/800/600'}
                                    alt={blogPost.title}
                                    className="blog-featured-image"
                                />
                                <div className="blog-article-content">
                                    <h1 className="blog-article-title">{blogPost.title}</h1>
                                    <div className="blog-article-meta">
                                        <div className="blog-meta-item">
                                            <User className="blog-meta-icon" size={16} />
                                            <span>
                                                {blogPost.author && blogPost.author.username
                                                    ? blogPost.author.username
                                                    : 'Unknown'}
                                            </span>
                                        </div>
                                        <div className="blog-meta-item">
                                            <CalendarDays className="blog-meta-icon" size={16} />
                                            <span>{new Date(blogPost.created_at).toLocaleDateString()}</span>
                                        </div>
                                    </div>
                                    {/* Render the full blog content */}
                                    <div
                                        className="blog-article-content-full"
                                        dangerouslySetInnerHTML={createSafeMarkup(blogPost.content)}
                                    />
                                    <button className="blog-back-btn" onClick={() => navigate(-1)}>
                                        Back to Blogs
                                    </button>
                                </div>
                            </article>
                        ) : (
                            !postsLoading && <p>Post not found.</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SingleBlogPage;
