// src/Components/Notifications.js

import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  clearErrors,
  markMessageAsNotNew,
  setSelectedUser,
} from '../slices/notificationSlice';
import { useNavigate } from 'react-router-dom';

const Notifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux state for errors, messages, current room, user info, etc.
  const errors = useSelector((state) => state.notifications.errors);
  const chatMessages = useSelector((state) => state.notifications.chatMessages);
  const currentRoom = useSelector((state) => state.notifications.currentRoom); // The user’s active room
  const users = useSelector((state) => state.notifications.users);

  // Identify if user is a doctor or patient
  const currentUserId = useSelector((state) => {
    if (state.user?.userData?.patient?.user?.id) {
      return state.user.userData.patient.user.id;
    } else if (state.doctor?.doctorData?.doctor?.user?.id) {
      return state.doctor.doctorData.doctor.user.id;
    }
    return null;
  });
  const isDoctor = useSelector((state) => !!state.doctor.doctorData?.doctor);

  // Keep track of which messages we’ve already shown a toast for
  const notifiedMessages = useRef(new Set());

  // Audio ref for notification sound
  const audioRef = useRef(null);

  // ---------------------------
  // Initialize the audio element
  // ---------------------------
  useEffect(() => {
    audioRef.current = new Audio('/static/audio/notification.mp3');
    audioRef.current.preload = 'auto';
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  // ---------------------------
  // ERROR NOTIFICATIONS
  // ---------------------------
  useEffect(() => {
    if (errors.length === 0) return;

    errors.forEach((error) => {
      // Filter out unwanted error messages
      const unwantedErrors = [
        'WebSocket encountered an error',
        'Not subscribed to this room',
      ];
      const isUnwanted = unwantedErrors.some((unwanted) =>
        error.message.startsWith(unwanted)
      );
      if (isUnwanted) {
        console.warn('Filtered out error message:', error.message);
        return;
      }

      // Possibly customize the error
      let errorMessage = error.message;
      if (errorMessage === 'Either you or the receiver is already in another call') {
        errorMessage = 'The user is currently busy on another call.';
      }

      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
        transition: Bounce,
      });
    });

    // Clear from store after displaying
    dispatch(clearErrors());
  }, [errors, dispatch]);

  // ---------------------------
  // MESSAGE NOTIFICATIONS
  // ---------------------------
  useEffect(() => {
    /**
     * We’ll loop through all rooms in chatMessages. 
     * If a message is from someone else, isNew=true, and not in currentRoom, we toast.
     */
    Object.entries(chatMessages).forEach(([roomName, messages]) => {
      messages.forEach((message) => {
        // Skip if it's from this user
        if (message.isCurrentUser) return;

        // Skip if we’re already in that room
        if (roomName === currentRoom) return;

        // Skip if not new
        if (!message.isNew) return;

        // Skip if we already notified
        if (notifiedMessages.current.has(message.id)) return;

        // We have a new message from someone else, not in the current room
        const senderFullName = message.senderFullName || 'Unknown User';
        const senderUserId = message.sender;

        // Play notification sound
        if (audioRef.current) {
          audioRef.current.currentTime = 0;
          const playPromise = audioRef.current.play();
          if (playPromise) {
            playPromise.catch((err) => {
              console.error('Notification sound play error:', err);
            });
          }
        }

        // Show the toast
        toast.info(`New message from ${senderFullName}: ${message.content}`, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'light',
          transition: Bounce,
          onClick: () => {
            // If we have a user object in the store
            let userObj = users[senderUserId];
            if (!userObj) {
              // fallback user if not found
              userObj = {
                user: {
                  id: senderUserId,
                  fullName: senderFullName,
                },
              };
            }
            // store it in Redux if you want the same "selectedUser" logic
            dispatch(setSelectedUser(userObj));

            // Then navigate to the 1-to-1 chat route
            if (isDoctor) {
              // Example => /doctors/chat/<patientUserId>
              navigate(`/doctors/chat/${senderUserId}`, {
                state: { selectedUser: userObj },
              });
            } else {
              // Example => /chat
              // (You might pass state or query param for user ID)
              navigate('/chat', {
                state: { selectedUser: userObj },
              });
            }
          },
        });

        // Mark as notified
        notifiedMessages.current.add(message.id);

        // Mark the message as not new in Redux
        dispatch(markMessageAsNotNew({ roomName, messageId: message.id }));
      });
    });
  }, [chatMessages, currentRoom, dispatch, users, isDoctor, navigate]);

  return <ToastContainer />;
};

export default Notifications;
